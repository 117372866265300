import Auth from '@aws-amplify/auth'
// import { axios } from '@/api/axios'
import { get } from '@/api/signout'
import urlJoin from 'url-join'
import { refreshToken } from '@/auth/token'

console.log(process.env.VUE_APP_AWS_COGNITO_REGION)
console.log(process.env.VUE_APP_AWS_USER_POOL_ID)
console.log(process.env.VUE_APP_AWS_USER_POOL_APP_CLIENT_ID)

/* eslint-disable @typescript-eslint/camelcase */
const awsmobile = {
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOL_APP_CLIENT_ID
}

Auth.configure(awsmobile)

type AuthInfo = {
  userName: string
  idToken: string
  accessToken: string
  refreshToken: string
  userPoolId: string
  userPoolWebClientId: string
}

export async function getAuthInfo() {
  const user = await Auth.currentAuthenticatedUser()

  if (user == null) {
    return undefined
  }

  const userName = user.getUsername()
  const idToken = user
    .getSignInUserSession()
    ?.getIdToken()
    .getJwtToken()
  const accessToken = user
    .getSignInUserSession()
    ?.getAccessToken()
    .getJwtToken()
  const refreshToken = user
    .getSignInUserSession()
    ?.getRefreshToken()
    .getToken()
  const { userPoolId, userPoolWebClientId } = Auth.configure()

  const info = {
    userName,
    idToken,
    accessToken,
    refreshToken,
    userPoolId,
    userPoolWebClientId
  }
  const result = Object.entries(info).some(
    ([, value]) => typeof value !== 'string'
  )
  return result ? undefined : (info as AuthInfo)
}

export async function signOut(services: string[], signOutId?: string) {
  // Sign out should work even if signed out from qt-account before.
  // That's because the other services might signed in yet.
  // This is for SSO.
  const token = await refreshToken()

  if (signOutId === undefined) {
    location.replace('/failed')
  }

  if (token != null) {
    if (!(await get(signOutId).catch(() => false))) {
      location.replace('/failed')
      return
    }

    await Auth.signOut({ global: true }).catch(() => undefined)
    localStorage.clear()
  }

  if (services.length === 0) {
    // All done
    location.replace('/')
    return
  }

  const nextService = services.shift()

  const queryParameter =
    services.length > 0
      ? `?serviceList=${encodeURIComponent(
          services.join(',')
        )}&signOutId=${signOutId}`
      : `?signOutId=${signOutId}`

  location.replace(urlJoin(`https://${nextService}/signout`, queryParameter))
}
