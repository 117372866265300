
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'
import { useRouter } from 'vue-router'
import ShowPassword from '@/components/ShowPassword.vue'

type Required = {
  isFalse: boolean
  check: (value: string) => boolean
  description: string
}

const specialLetters = `^$*.[]{}()?"!@#%&/\\,><':;|_~`

export default defineComponent({
  components: {
    ShowPassword
  },
  props: {
    email: {
      type: String, // 本当は文字列の配列
      required: true
    }
  },
  emits: ['reset'],
  setup(props, { emit }) {
    const { t } = useTranslation()
    const verifyCode = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const emailAddr = ref(props.email)

    const showPassword = ref(false)
    const router = useRouter()
    const refVerificationInput = ref<HTMLElement | null>(null)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const required = reactive<Required[]>([
      {
        isFalse: false,
        check: (value: string) =>
          !(value.length < 8 || value.indexOf(' ') !== -1),
        description: t('At least 8 characters with no space')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const upperCase = /[A-Z]{1,}/
          return upperCase.test(value)
        },
        description: t('At least 1 upper case letter')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const lowerCase = /[a-z]{1,}/
          return lowerCase.test(value)
        },
        description: t('At least 1 lower case letter')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const number = /[0-9]{1,}/
          return number.test(value)
        },
        description: t('At least 1 number')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const special = /(?=.*?[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`])/
          return special.test(value)
        },
        description: t(
          'At least 1 of the following special charactors from {specialChars}',
          { specialChars: specialLetters }
        )
      }
    ])

    onMounted(() => {
      onFocus(refVerificationInput)
    })

    const onReset = () => {
      if (!inProgress.value) {
        emit('reset')
      }
    }

    const onShowPassword = () => (showPassword.value = !showPassword.value)

    const checkPassword = () => {
      errorMsg.value = ''
      required.forEach(require => {
        require.isFalse = !require.check(password.value)
        if (require.isFalse) {
          errorMsg.value = t('Invalid password')
        }
      })

      if (errorMsg.value.length > 0) {
        onFocus(refPasswordInput)
        return true
      }

      return false
    }

    const errorHandler = (err: any) => {
      if (err.code === 'CodeMismatchException') {
        errorMsg.value = t(err.message)
        onFocus(refVerificationInput)
      } else if (err.code === 'InvalidParameterException') {
        // 基本的にはここには飛んでこないはず。
        errorMsg.value = t('Invalid password')
        onFocus(refPasswordInput)
      } else {
        errorMsg.value = err.message
        onFocus(refVerificationInput)
      }
    }

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true
      errorMsg.value = ''

      if (emailAddr.value.length === 0) {
        // ここにくるとしたら実装のバグ
        throw Error('FIXME!!')
      }

      if (verifyCode.value.length === 0) {
        errorMsg.value = t('Enter your verify code')
        onFocus(refVerificationInput)
        inProgress.value = false
        return
      }

      if (checkPassword()) {
        inProgress.value = false
        return
      }

      Auth.forgotPasswordSubmit(
        emailAddr.value,
        verifyCode.value,
        password.value
      )
        .then(() => {
          router.push('success')
        })
        .catch(err => {
          errorHandler(err)
          inProgress.value = false
        })
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    return {
      t,
      keyDown,
      onShowPassword,
      onNext,
      onReset,
      required,
      refVerificationInput,
      showPassword,
      refPasswordInput,
      inProgress,
      errorMsg,
      verifyCode,
      password,
      specialLetters
    }
  }
})
