
import { defineComponent, ref, onMounted } from 'vue'
import { post } from '@/api/token'
import { getSessionIdUrl, onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { getAuthInfo } from '@/auth'
import { useTranslation } from '@/locales'
import { refreshToken } from '@/auth/token'
import ShowPassword from '@/components/ShowPassword.vue'

export default defineComponent({
  beforeRouteEnter: async ({ query }, __, next) => {
    // This function will refresh access or id token if needed
    const res = await refreshToken()

    if (res == null) {
      next()
      return
    }

    // This user is authenticated.
    const authInfo = await getAuthInfo()

    await post(authInfo)
      .then(({ sessionId }) => {
        const redirectTo =
          typeof query.redirect !== 'string'
            ? process.env.VUE_APP_DEFAULT_REDIRECT_URL!
            : query.redirect

        const url = getSessionIdUrl(redirectTo, sessionId)
        location.replace(url)
      })
      .catch(err => {
        window.localStorage.clear()

        location.replace('/failed')
      })
  },
  components: {
    ShowPassword
  },
  setup() {
    const { t } = useTranslation()
    const email = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const refEmailInput = ref<HTMLElement | null>(null)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const showPassword = ref(false)

    const onShowPassword = () => (showPassword.value = !showPassword.value)

    onMounted(() => {
      onFocus(refEmailInput)
    })

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true
      errorMsg.value = ''

      if (password.value.length === 0) {
        errorMsg.value = t('Please enter your password')
        onFocus(refPasswordInput)
      }

      if (email.value.length === 0) {
        errorMsg.value = t('Please enter your email address')
        onFocus(refEmailInput)
      }

      if (errorMsg.value.length > 0) {
        inProgress.value = false
        return
      }

      await Auth.signIn(email.value, password.value)
        .then(async () => {
          location.replace(window.location.href)
        })
        .catch(err => {
          if (err.code === 'NotAuthorizedException') {
            errorMsg.value = t(err.message)
          } else {
            errorMsg.value = err.message
          }
          onFocus(refEmailInput)
          inProgress.value = false
        })
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    const gotoForgotPassword = () => {
      if (!inProgress.value) {
        location.replace('forgot-password')
      }
    }

    return {
      t,
      keyDown,
      inProgress,
      errorMsg,
      email,
      password,
      refEmailInput,
      refPasswordInput,
      showPassword,
      onShowPassword,
      onNext,
      gotoForgotPassword
    }
  }
})
