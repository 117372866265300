<template>
  <div class="container mx-auto h-screen flex items-center ">
    <div class=" w-full">
      <div class="flex justify-center">
        <div class="w-96 md:border md:shadow-md bg-white p-4 md:p-8">
          <div class="flex items-center justify-items-center mb-4">
            <img src="@/assets/img/title.png" alt="image" class="mr-2" />
            <h1 class="text-gray-500 text-xl font-bold">
              {{ t('QUALITIA') }}
            </h1>
          </div>

          <h1 class="text-gray-700 text-2xl font-bold mb-4">
            {{ t('Reset your password') }}
          </h1>

          <input
            type="text"
            ref="refVerificationInput"
            :disabled="inProgress"
            @keydown.enter="keyDown"
            class="px-1 border-b focus:outline-none border-gray-200 w-full mb-2"
            v-model="verifyCode"
            :placeholder="t('Enter your verify code')"
          />

          <p
            :class="[
              inProgress ? 'text-gray-500' : 'text-blue-500 cursor-pointer',
              ' text-xs mb-6 '
            ]"
            @click="onReset"
          >
            {{ t('Reissu a varification code') }} >>
          </p>

          <input
            :type="showPassword ? 'text' : 'password'"
            ref="refPasswordInput"
            :disabled="inProgress"
            @keydown.enter="keyDown"
            class="px-1 border-b focus:outline-none border-gray-200 w-full mb-2"
            v-model="password"
            :placeholder="t('Enter your new password')"
          />

          <div class="mb-4">
            <ShowPassword
              @showPassword="onShowPassword"
              :inProgress="inProgress"
            />
          </div>
          <!--
          <InputPassword
            @keyDown="keyDown"
            v-model="password"
            :inProgress="inProgress"
            :placeholder="'Enter your new password'"
          /> -->
          <div class="text-xs">
            <h2 class="text-gray-600 mb-1">{{ t('Please must include:') }}</h2>
            <p
              v-for="(require, index) in required"
              :class="[require.isFalse ? 'text-red-600' : 'text-gray-600', '']"
              :key="index"
            >
              {{ require.description }}
            </p>
          </div>

          <p
            :class="[
              errorMsg.length === 0 ? 'invisible' : '',
              'h-4 text-red-600 text-xs mb-2 mt-2'
            ]"
          >
            {{ errorMsg }}
          </p>

          <div class="w-full flex justify-end">
            <button
              :class="[
                inProgress ? 'bg-blue-200' : 'bg-blue-500',
                'px-2 py-1  text-white w-32'
              ]"
              @click="onNext"
            >
              {{ t('Next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { useTranslation } from '@/locales'
import { useRouter } from 'vue-router'
import ShowPassword from '@/components/ShowPassword.vue'

type Required = {
  isFalse: boolean
  check: (value: string) => boolean
  description: string
}

const specialLetters = `^$*.[]{}()?"!@#%&/\\,><':;|_~`

export default defineComponent({
  components: {
    ShowPassword
  },
  props: {
    email: {
      type: String, // 本当は文字列の配列
      required: true
    }
  },
  emits: ['reset'],
  setup(props, { emit }) {
    const { t } = useTranslation()
    const verifyCode = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const emailAddr = ref(props.email)

    const showPassword = ref(false)
    const router = useRouter()
    const refVerificationInput = ref<HTMLElement | null>(null)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const required = reactive<Required[]>([
      {
        isFalse: false,
        check: (value: string) =>
          !(value.length < 8 || value.indexOf(' ') !== -1),
        description: t('At least 8 characters with no space')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const upperCase = /[A-Z]{1,}/
          return upperCase.test(value)
        },
        description: t('At least 1 upper case letter')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const lowerCase = /[a-z]{1,}/
          return lowerCase.test(value)
        },
        description: t('At least 1 lower case letter')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const number = /[0-9]{1,}/
          return number.test(value)
        },
        description: t('At least 1 number')
      },
      {
        isFalse: false,
        check: (value: string) => {
          const special = /(?=.*?[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`])/
          return special.test(value)
        },
        description: t(
          'At least 1 of the following special charactors from {specialChars}',
          { specialChars: specialLetters }
        )
      }
    ])

    onMounted(() => {
      onFocus(refVerificationInput)
    })

    const onReset = () => {
      if (!inProgress.value) {
        emit('reset')
      }
    }

    const onShowPassword = () => (showPassword.value = !showPassword.value)

    const checkPassword = () => {
      errorMsg.value = ''
      required.forEach(require => {
        require.isFalse = !require.check(password.value)
        if (require.isFalse) {
          errorMsg.value = t('Invalid password')
        }
      })

      if (errorMsg.value.length > 0) {
        onFocus(refPasswordInput)
        return true
      }

      return false
    }

    const errorHandler = (err: any) => {
      if (err.code === 'CodeMismatchException') {
        errorMsg.value = t(err.message)
        onFocus(refVerificationInput)
      } else if (err.code === 'InvalidParameterException') {
        // 基本的にはここには飛んでこないはず。
        errorMsg.value = t('Invalid password')
        onFocus(refPasswordInput)
      } else {
        errorMsg.value = err.message
        onFocus(refVerificationInput)
      }
    }

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true
      errorMsg.value = ''

      if (emailAddr.value.length === 0) {
        // ここにくるとしたら実装のバグ
        throw Error('FIXME!!')
      }

      if (verifyCode.value.length === 0) {
        errorMsg.value = t('Enter your verify code')
        onFocus(refVerificationInput)
        inProgress.value = false
        return
      }

      if (checkPassword()) {
        inProgress.value = false
        return
      }

      Auth.forgotPasswordSubmit(
        emailAddr.value,
        verifyCode.value,
        password.value
      )
        .then(() => {
          router.push('success')
        })
        .catch(err => {
          errorHandler(err)
          inProgress.value = false
        })
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    return {
      t,
      keyDown,
      onShowPassword,
      onNext,
      onReset,
      required,
      refVerificationInput,
      showPassword,
      refPasswordInput,
      inProgress,
      errorMsg,
      verifyCode,
      password,
      specialLetters
    }
  }
})
</script>
<style>
input:checked + div {
  @apply border-blue-500;
}
input:checked + div svg {
  @apply block;
}
</style>
