import Index from '@/views/Index.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import Success from '@/views/Success.vue'
import Failed from '@/views/Failed.vue'
import SignOut from '@/views/SignOut.vue'
import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Index
  },
  {
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    path: '/success',
    component: Success
  },
  {
    path: '/signout',
    component: SignOut
  },
  {
    path: '/failed',
    component: Failed
  }
]

export default routes
