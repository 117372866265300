import Auth from '@aws-amplify/auth'

function _redirectToSignIn() {
  location.replace(process.env.QT_ACCOUNT_URL!)
}

let _jwtToken: null | string = null

export async function refreshToken() {
  // Check and refresh token if need
  const res = await Auth.currentUserInfo()

  if (res == null || Object.keys(res).length === 0) {
    return undefined
  }

  const user = await Auth.currentAuthenticatedUser().catch(() => undefined)

  if (user == null) {
    return undefined
  }

  _jwtToken = user.signInUserSession.idToken.jwtToken

  return _jwtToken
}

export function getToken() {
  return _jwtToken
}
