<template>
  <div class="container mx-auto h-screen flex items-center ">
    <div class=" w-full">
      <div class="flex justify-center">
        <div class="w-96 h-96 md:border md:shadow-md bg-white p-4 md:p-8">
          <div class="flex items-center justify-items-center mb-4">
            <img src="@/assets/img/title.png" alt="image" class="mr-2" />
            <h1 class="text-gray-500 text-xl font-bold">
              {{ t('QUALITIA') }}
            </h1>
          </div>

          <h1 class="text-gray-700 text-2xl font-bold mb-4">
            {{ t('Sign In') }}
          </h1>

          <input
            class="px-1 border-b focus:outline-none border-gray-200 w-full mb-8"
            ref="refEmailInput"
            v-model="email"
            @keydown.enter="keyDown"
            :disabled="inProgress"
            :placeholder="t('Enter your email')"
          />
          <input
            :type="showPassword ? 'text' : 'password'"
            ref="refPasswordInput"
            :disabled="inProgress"
            @keydown.enter="keyDown"
            class="px-1 border-b focus:outline-none border-gray-200 w-full mb-2"
            v-model="password"
            :placeholder="t('Enter your new password')"
          />

          <div class="mb-4">
            <ShowPassword
              @showPassword="onShowPassword"
              :inProgress="inProgress"
            />
          </div>

          <p
            @click="gotoForgotPassword"
            :class="[
              inProgress ? 'text-gray-500' : 'text-blue-500 cursor-pointer',
              ' text-xs mb-6 '
            ]"
          >
            {{ t('Forgot password') }} >>
          </p>

          <p
            :class="[
              errorMsg.length === 0 ? 'invisible' : '',
              'h-4 text-red-600 text-xs mb-2'
            ]"
          >
            {{ errorMsg }}
          </p>
          <div class="w-full flex justify-end">
            <button
              :class="[
                inProgress ? 'bg-blue-200' : 'bg-blue-500',
                'px-2 py-1  text-white w-32'
              ]"
              @click="onNext"
            >
              {{ t('Next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { post } from '@/api/token'
import { getSessionIdUrl, onFocus } from '@/utils'
import Auth from '@aws-amplify/auth'
import { getAuthInfo } from '@/auth'
import { useTranslation } from '@/locales'
import { refreshToken } from '@/auth/token'
import ShowPassword from '@/components/ShowPassword.vue'

export default defineComponent({
  beforeRouteEnter: async ({ query }, __, next) => {
    // This function will refresh access or id token if needed
    const res = await refreshToken()

    if (res == null) {
      next()
      return
    }

    // This user is authenticated.
    const authInfo = await getAuthInfo()

    await post(authInfo)
      .then(({ sessionId }) => {
        const redirectTo =
          typeof query.redirect !== 'string'
            ? process.env.VUE_APP_DEFAULT_REDIRECT_URL!
            : query.redirect

        const url = getSessionIdUrl(redirectTo, sessionId)
        location.replace(url)
      })
      .catch(err => {
        window.localStorage.clear()

        location.replace('/failed')
      })
  },
  components: {
    ShowPassword
  },
  setup() {
    const { t } = useTranslation()
    const email = ref('')
    const password = ref('')
    const errorMsg = ref('')
    const inProgress = ref(false)
    const refEmailInput = ref<HTMLElement | null>(null)
    const refPasswordInput = ref<HTMLElement | null>(null)
    const showPassword = ref(false)

    const onShowPassword = () => (showPassword.value = !showPassword.value)

    onMounted(() => {
      onFocus(refEmailInput)
    })

    const onNext = async () => {
      if (inProgress.value) {
        return
      }

      inProgress.value = true
      errorMsg.value = ''

      if (password.value.length === 0) {
        errorMsg.value = t('Please enter your password')
        onFocus(refPasswordInput)
      }

      if (email.value.length === 0) {
        errorMsg.value = t('Please enter your email address')
        onFocus(refEmailInput)
      }

      if (errorMsg.value.length > 0) {
        inProgress.value = false
        return
      }

      await Auth.signIn(email.value, password.value)
        .then(async () => {
          location.replace(window.location.href)
        })
        .catch(err => {
          if (err.code === 'NotAuthorizedException') {
            errorMsg.value = t(err.message)
          } else {
            errorMsg.value = err.message
          }
          onFocus(refEmailInput)
          inProgress.value = false
        })
    }

    const keyDown = async (event: any) => {
      if (event.keyCode === 13) {
        onNext()
      }
    }

    const gotoForgotPassword = () => {
      if (!inProgress.value) {
        location.replace('forgot-password')
      }
    }

    return {
      t,
      keyDown,
      inProgress,
      errorMsg,
      email,
      password,
      refEmailInput,
      refPasswordInput,
      showPassword,
      onShowPassword,
      onNext,
      gotoForgotPassword
    }
  }
})
</script>
